
import Routing from './routing'
import init from '@web3-onboard/core'
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets'
import { Web3OnboardProvider } from '@web3-onboard/react'
import walletConnectModule from '@web3-onboard/walletconnect'



const injected = injectedModule({
  displayUnavailable: [ProviderLabel.MetaMask, ProviderLabel.Trust],
  filter: {
    [ProviderLabel.Detected]: ['Android', 'desktop', 'macOS', 'iOS', 'Android Browser']
  }
})
const wcV2InitOptions = {
  projectId: '699a02c84cb99aaee1f0d289ddff22f5',
  requiredChains: [
    56
  ],
}

const walletConnect = walletConnectModule(wcV2InitOptions)

const web3Onboard = init({
  wallets: [
    injected,
    walletConnect
  ],
  theme: 'dark',
  connect: {
    autoConnectLastWallet: false
  },
  chains: [
    {
      id: '0x38',
      token: 'BNB',
      label: 'Binance Smart Chain',
      rpcUrl: 'https://bsc-mainnet.public.blastapi.io'
    },
  ],

})

function App() {
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>
      <Routing />
    </Web3OnboardProvider>
  )
};

export default App;
