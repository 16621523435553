import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../ui/layout/footer";
import BuyToken from "../ui/pages/buyToken";
import LandingPage from "../ui/pages/landingPage";
import Header from "../ui/layout/header";

function Routing() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/buyToken" element={<BuyToken />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default Routing;
