import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const endDate = new Date('2024-06-01T00:00:00');

  const calculateTimeRemaining = () => {
    const currentTime = new Date();
    const difference = endDate.getTime() - currentTime.getTime();

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  return (
    <>
      <main>
        <section className="hero hero__ico pos-rel" >
          <div className="hero__bg" style={{ backgroundImage: "url('assets/img/bg/hero_bg.png'" }} ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="hero__content">
                  <h1 className="title mb-45">Explore the Power  of Digital Currency  <span> Introducing Ecash-X ICO </span>  </h1>
                  <div className="btns">
                    <Link to="/buyToken" className="thm-btn"> BUY TOKEN </Link>
                    <a className="thm-btn thm-btn--light" href="ECASH-X FINAL WHITEPAPER.pdf" target='_blank'>WHITEPAPER</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero__explore-wrap text-center">
                  <div className="hero__explore text-center">
                    <div className="scroll-down"></div>
                    <span>Explore Causes</span>
                  </div>
                  <div className="hero__countdown">
                    <h6 className="text-center">ICO is now OPEN ..</h6>
                    <h6 className="text-center"> <small>Launching  Date</small> : 01 July 2024</h6>
                    <h6 className="text-center"><small>Closing Date</small> : 30 June 2024 </h6>
                    {/* <div className="countdown ul_li_center" data-countdown="2024/08/28">
                      <div className='single'> <h1>{timeRemaining.days}</h1>in<p>DAYS</p></div>
                      <div className='single'><h1>{timeRemaining.hours}</h1><p>HOURS</p></div>
                      <div className='single'><h1>{timeRemaining.minutes}</h1><p>MINUTES</p></div>
                      <div className='single'><h1>{timeRemaining.seconds}</h1><p>SECONDS</p></div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__shape">
            <div className="shape shape--1">
              <img src="assets/img/shape/h_shape.png" alt="" />
            </div>
            <div className="shape shape--2">
              <img src="assets/img/shape/h_shape2.png" alt="" />
            </div>
            <div className="shape shape--3">
              <img src="assets/img/shape/h_shape3.png" alt="" />
            </div>
          </div>
          <div className="hero__coin">
            <div className="coin coin--1">
              <img src="assets/img/icon/coin1.png" alt="" />
            </div>
            <div className="coin coin--2">
              <img src="assets/img/icon/coin2.png" alt="" />
            </div>
            <div className="coin coin--3">
              <img src="assets/img/icon/coin3.png" alt="" />
            </div>
            <div className="coin coin--4">
              <img src="assets/img/icon/coin4.png" alt="" />
            </div>
            <div className="coin coin--5">
              <img src="assets/img/icon/coin5.png" alt="" />
            </div>
            <div className="coin coin--6">
              <img src="assets/img/icon/coin6.png" alt="" />
            </div>
          </div>
        </section>

        <section className="partners_sec pt-55 pb-55 mb-5">
          <div class="container">

            <div className="sec-title text-center mb-20">
              <h5 className="sec-title__subtitle"> Our Partners</h5>
              <h2 className="sec-title__title"> Channel Partners and Promoters </h2>
            </div>


            <div class="partner_grid">
              <a href="#/"><img src="assets/img/partners/partner_logo_1.svg" alt="partner 1" /> </a>
              <a href="#/"><img src="assets/img/partners/partner_logo_3.png" alt="partner 1" /> </a>
              <a href="#/"><img src="assets/img/partners/partner_logo_6.png" alt="partner 1" /> </a>
              <a href="#/"><img src="assets/img/partners/partner_logo_4.png" alt="partner 1" /> </a>
              <a href="#/"><img src="assets/img/partners/partner_logo_2.png" alt="partner 1" /> </a>
              <a href="#/"><img src="assets/img/partners/partner_logo_5.png" alt="partner 1" /> </a>
            </div>
          </div>
        </section>





        <section id="about" className="about pos-rel pb-140">
          <div className="container">
            <div className="row align-items-center mt-none-30">
              <div className="col-lg-6 mt-30">
                <div className="about__img pos-rel wow fadeInLeft">
                  <img src="assets/img/about/about_img.png" alt="" />
                  <div className="about__shape">
                    <img src="assets/img/shape/about_shape.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-30">
                <div className="about__content wow fadeInRight" data-wow-delay="100ms">
                  <div className="sec-title mb-35">
                    <h5 className="sec-title__subtitle">WHAT IS ICO CRYPTO</h5>
                    <h2 className="sec-title__title mb-25">Something About Ecash-X</h2>
                    <p>
                      At Ecash-X, we are on a mission to revolutionize the world of digital currency.
                      Founded by a team of passionate blockchain enthusiasts, our vision is to create a
                      decentralized financial ecosystem that empowers individuals worldwide.
                    </p>
                  </div>
                  <ul className="about__list ul_li">
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" /> Decentralized Platform </li>
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" /> Rewards Meachanism </li>
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" />Crowd Wisdom</li>
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" />Investor Protection</li>
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" />Token Sale Phases</li>
                    <li><img src="assets/img/icon/a_arrow.svg" alt="" />Exchange Listing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="about__sec-shape">
            <img src="assets/img/shape/s_shape2.png" alt="" />
          </div>
        </section>
        <section id='features' className="features pos-rel pb-150 mb-0-pb">
          <div className="container">
            <div className="sec-title text-center mb-95">
              <h5 className="sec-title__subtitle">WHY CHOOSE US</h5>
              <h2 className="sec-title__title mb-25">Why choose our Ecash-X?</h2>
            </div>
            <div className="feature__wrap pos-rel ul_li_between">
              <div className="feature__item text-center">
                <div className="icon">
                  <img src="assets/img/icon/f_01.svg" alt="" />
                </div>
                <h4>Easy Pay</h4>
              </div>
              <div className="feature__item text-center">
                <div className="icon">
                  <img src="assets/img/icon/f_02.svg" alt="" />
                </div>
                <h4>Fast</h4>
              </div>
              <div className="feature__item text-center">
                <div className="icon">
                  <img src="assets/img/icon/f_03.svg" alt="" />
                </div>
                <h4>Reliable</h4>
              </div>
              <div className="feature__item text-center">
                <div className="icon">
                  <img src="assets/img/icon/f_04.svg" alt="" />
                </div>
                <h4>Smooth</h4>
              </div>
              <div className="feature__item text-center">
                <div className="icon">
                  <img src="assets/img/icon/f_05.svg" alt="" />
                </div>
                <h4>Secured</h4>
              </div>
              <div className="feature__line-shape">
                <img src="assets/img/shape/f_shape.png" alt="" />
              </div>
            </div>
          </div>
          <div className="feature__sec-shape">
            <img src="assets/img/shape/s_shape1.png" alt="" />
          </div>
        </section>
        <section id='token' className="token pt-125">
          <div className="container">
            <div className="row align-items-center mt-none-30">
              <div className="col-lg-5 mt-30">
                <div className="token__content wow fadeInLeft">
                  <div className="sec-title mb-10">
                    <h5 className="sec-title__subtitle">Tokenomics</h5>
                    <h2 className="sec-title__title">Token allocation & funds distribution</h2>
                  </div>
                  <div className="token__info mt-20">
                    <h2 className="mb-2" > <small>Token Name = </small> Ecash-X </h2>
                    <h2 className="mb-2" > <small> Total Supply =  </small> 40 Million</h2>
                    <h2 className="mb-2" > <small> Symbol =  </small> ECASHX</h2>
                    <h2 className="mb-2" > <small> Decimal =  </small> 18</h2>
                    <h2 className="mb-2" > <small> Network =  </small> BEP20</h2>
                    <h2 className="mb-2" > <small>For Presales 1 Million  </small></h2>
                    <h2 className="mb-2" > <small> Reserve Amount 39 Million  </small></h2>
                    <h2> <small> ICO Price <small>(Phase 1)</small> = </small> USDT 0.25</h2>

                    <div className="token-btn mt-40">
                      <a className="thm-btn" href="/buyToken"> Buy  Token  </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 mt-30">
                <div className="tab-content wow fadeInRight" data-wow-delay="100ms" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="token__img">
                      <img src="assets/img/token/token_chart.svg" alt="" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="token__img">
                      <img src="assets/img/token/token_chart2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="roadmap" className="roadmap pt-lg-80 pb-md-100 pt-145 pos-rel pb-200">
          <div className="container-fluid">
            <div className="sec-title text-center mb-60">
              <h5 className="sec-title__subtitle">Road map</h5>
              <h2 className="sec-title__title"> Ecash-X Roadmap </h2>
            </div>
            <div className="roadmap__wrap ul_li_between">


              <div className="roadmap__item">
                <div className="roadmap__head ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/rm_01.svg" alt="" />
                  </div>
                  <h3>Q1 2024</h3>
                  {/* <h3>Project Plan</h3> */}
                </div>
                <ul className="roadmap__info list-unstyled">
                  <li>-Market Research & <br />Idea Development</li>
                </ul>
                <span className="number">Q1 <br /> 2024</span>
              </div>


              <div className="roadmap__item">
                <div className="roadmap__head ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/rm_01.svg" alt="" />
                  </div>
                  <h3> Q2 2024 </h3>
                  {/* <h3>Launching Ecash-X</h3> */}
                </div>
                <ul className="roadmap__info list-unstyled">
                  <li>-Launch of ICO Website <br />for Ecash-X Token</li>
                  <li>-Listing of Ecash-X Token on DEX</li>
                  <li>-Listing of Ecash-X Token <br />on some popular CEX</li>
                </ul>
                <span className="number">Q2 <br /> 2024</span>
              </div>


              <div className="roadmap__item">
                <div className="roadmap__head ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/rm_02.svg" alt="" />
                  </div>
                  <h3>Q3 2024</h3>
                  {/* <h3>Launching Exchange</h3> */}
                </div>
                <ul className="roadmap__info list-unstyled">
                  <li>-Launch of Ecash-X <br />Centralized Exchange</li>
                  <li>-Launch various <br />staking plans</li>
                  <li>-Ecash-X will be investment in top miner making companies like bitmain</li>
                  <li>-Invest multidimensional to give 10x growth to funds</li>
                </ul>
                <span className="number">Q3 <br /> 2024</span>
              </div>


              <div className="roadmap__item">
                <div className="roadmap__head ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/rm_02.svg" alt="" />
                  </div>
                  <h3> Q4 2024 </h3>
                  {/* <h3>Launching Blockchain</h3> */}
                </div>
                <ul className="roadmap__info list-unstyled">
                  <li>-Launch of Ecash-X own Blockchain  </li>
                  <li>-Total project investment of phase 1 is more than 10 million USD</li>
                  <li>-In phase 2 it will be 5 million USD  </li>
                  <li>-In phase 3 the investment size will be more than 15 million USD  </li>
                </ul>
                <span className="number">Q4 <br /> 2024</span>
              </div>
              <div className="roadmap__item">
                <div className="roadmap__head ul_li">
                  <div className="icon">
                    <img src="assets/img/icon/rm_02.svg" alt="" />
                  </div>
                  <h3>Q1 2025 </h3>
                  {/* <h3>Launching DeFi Wallet</h3> */}
                </div>
                <ul className="roadmap__info list-unstyled">
                  <li>-Launch DeFi Wallet for Ecash-X Blockchain</li>
                  <li>-Mobile Apps for DeFi Wallet</li>
                  <li>-Ecash-X will be worth Billion Dollar project  </li>
                </ul>
                <span className="number">Q1 <br /> 2025</span>
              </div>


              <div className="roadmap__shape">
                <img src="assets/img/shape/rm_shape.png" alt="" />
              </div>


              <div className="roadmap__year">
                <h3 className="year year--start">2024</h3>
                <h3 className="year year--end">2025</h3>
              </div>


            </div>
          </div>
          <div className="roadmap__sec-shape">
            <div className="shape shape--1">
              <img src="assets/img/shape/s_shape1.png" alt="" />
            </div>
            <div className="shape shape--2">
              <img src="assets/img/shape/s_shape2.png" alt="" />
            </div>
          </div>
        </section>
        <section className="token-info pos-rel pt-125 pb-75">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="token-info__img">
                  <img src="assets/img/whitepaper.svg" alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="  text-start ">
                  <h5 className="sec-title__subtitle"> Explore E-Cash Token</h5>
                  <h2 className="sec-title__title"> WHITEPAPER </h2>
                </div>

                <div className="token-info--info-wrap ul_li">
                  <ul className="token-info__list token-info--start">
                    <li>
                      <h4> <a href="ECASH-X FINAL WHITEPAPER.pdf" target='_blank'></a>White Paper</h4>
                    </li>

                  </ul>
                  <ul className="token-info__list token-info--end">
                    <li>
                      <h4>Privacy & Policy </h4>
                    </li>
                  </ul>
                </div>
                <div className="token-btn mt-40 px-md-3">
                  <a className="thm-btn btn-block w-100 text-center" href="ECASH-X FINAL WHITEPAPER.pdf" target='_blank' download> Download Whitepaper </a>
                </div>
              </div>
            </div>
          </div>
          <div className="token-info__shape">
            <div className="shape shape--1">
              <img src="assets/img/shape/ti_shape.png" alt="" />
            </div>
            <div className="shape shape--2">
              <img src="assets/img/shape/ti_shape2.png" alt="" />
            </div>
          </div>
        </section>




      </main>
    </>
  )
}

export default LandingPage
