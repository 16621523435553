import React, { useState, useEffect } from 'react';
import { useConnectWallet } from '@web3-onboard/react'
import { ethers } from 'ethers'
import saleContract from './TokenSale.json'
import usdtContract from './UsdtContract.json'
import LoaderHelper from '../../../CustomComponent/Loading/LoaderHelper';

const BuyToken = () => {
    const [connected, setConnected] = useState(false);
    const [address, setAddress] = useState("");
    const [usdt, setUSDT] = useState("");
    const [ecash, setEcash] = useState("")
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

    const ecashToUsdtRate = 0.25;


    let signer;
    let userAddress;
    let ethersProvider;


    const Buy = async () => {
        LoaderHelper?.loaderStatus(true);
        try {
            if (!wallet) {
                connect();
            }
            ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
            signer = ethersProvider.getSigner();
            userAddress = wallet.accounts[0];
            let USERADDRESS = userAddress.address;
            try {
                const usdtIntance = new ethers.Contract(usdtContract.address, usdtContract.abi, signer)
                const saleInstance = new ethers.Contract(saleContract.address, saleContract.abi, signer)
                const amountUSDT = usdt
                const approveAmount = ethers.utils.parseEther(amountUSDT.toString());
                try {
                    await usdtIntance.approve(saleContract.address, approveAmount);
                    LoaderHelper?.loaderStatus(true);
                    await saleInstance.buyTokens(approveAmount, userAddress.address);
                } catch (error) {
                    console.log("Error in buying...", error)
                }
            } catch (error) {

            }
            console.log("Transaction successful!");
        } catch (error) {
            console.error("Transaction failed:", error);

        }
        setEcash("");
        setUSDT("");
    }

    const calculateUSDT = (ecashValue) => {
        return (ecashValue * ecashToUsdtRate).toFixed(2);
    }


    useEffect(() => {
        const walletConnected = localStorage.getItem('walletConnected');
        const walletAddress = localStorage.getItem('walletAddress');
        if (walletConnected === 'true' && walletAddress) {
            setConnected(true);
            setAddress(walletAddress);
        }
    }, []);

    return (
        <>
            <main>
                <section className="hero hero__ico pos-rel buy_token_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-banner text-center">
                                    <h2 className="mb-3 ">Buy E-Cash Token</h2>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page"> Buy Token</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="justify-content-center mt-5  row">
                            <div className="m-auto mr-b30 col-sm-12 col-md-8 col-lg-6">
                                <div className="card card_ssm bg-primary">
                                    <div className="card-body p-md-5 p-3  currency-form ">
                                        <div className="swap_form form-group mb-4"><label className="mb-1"> USDT </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="usdt"
                                                id="ETA"
                                                placeholder="Enter Amount..."
                                                value={usdt}
                                                onChange={(e) => {
                                                    setUSDT(e.target.value);
                                                    setEcash((parseFloat(e.target.value) / ecashToUsdtRate).toFixed(2));
                                                }}
                                            />

                                        </div>
                                        <div className="swap_form form-group mb-3"><label className="mb-1">Ecash-X Coin</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="ecash"
                                                id="ET"
                                                placeholder="Enter Token..."
                                                value={ecash}
                                                onChange={(e) => {
                                                    setEcash(e.target.value);
                                                    setUSDT(calculateUSDT(parseFloat(e.target.value)));
                                                }}
                                            />

                                        </div>
                                        <div className="balance-libra card-success mb-5">
                                            <div className="token-img-bg_right" id="lc-data"> Price:- <b>$ {ecashToUsdtRate}</b> </div>
                                        </div>
                                        {connected && (
                                            <p>Connected: {address}</p>
                                        )}
                                        <button type="button" className="btn btn-block btn-block w-100" id="connect_wallet1" onClick={Buy}> BUY Ecash-X TOKEN</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero__shape">
                        <div className="shape shape--1">
                            <img src="assets/img/shape/h_shape.png" alt="" />
                        </div>
                        <div className="shape shape--2">
                            <img src="assets/img/shape/h_shape2.png" alt="" />
                        </div>
                        <div className="shape shape--3">
                            <img src="assets/img/shape/h_shape3.png" alt="" />
                        </div>
                    </div>
                    <div className="hero__coin">
                        <div className="coin coin--1">
                            <img src="assets/img/icon/coin1.png" alt="" />
                        </div>
                        <div className="coin coin--2">
                            <img src="assets/img/icon/coin2.png" alt="" />
                        </div>
                        <div className="coin coin--3">
                            <img src="assets/img/icon/coin3.png" alt="" />
                        </div>
                        <div className="coin coin--4">
                            <img src="assets/img/icon/coin4.png" alt="" />
                        </div>
                        <div className="coin coin--5">
                            <img src="assets/img/icon/coin5.png" alt="" />
                        </div>
                        <div className="coin coin--6">
                            <img src="assets/img/icon/coin6.png" alt="" />
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default BuyToken;
