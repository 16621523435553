import React from 'react'

const footer = () => {
    return (
        <>
            <footer class="site-footer footer__ico pos-rel" style={{ backgroundImage: "url('assets/img/bg/footer_bg.png'" }}>
                <div class="container">
                    <div class="footer__bottom ul_li_between mt-50">
                        <div class="footer__logo mt-20">
                            <a href="index.html"><img src="assets/img/logo/logo.svg" alt="" /></a>
                        </div>
                        <ul class="footer__social ul_li mt-20">
                            <li><a href="#!"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#!"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#!"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="#!"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#!"><i class="fab fa-telegram-plane"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="footer__copyright mt-25">
                    <div class="container">
                        <div class="footer__copyright-inner ul_li_between justify-content-center">
                            <div class="footer__copyright-text mt-15">
                                Copyright © 2024. All Rights Reserved Ecash-X Token
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer__icon-shape">
                    <div class="icon icon--2">
                        <div data-parallax='{"x" : 80}'>
                            <img src="assets/img/shape/f_icon2.png" alt="" />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default footer
