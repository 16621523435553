import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { init, useConnectWallet } from '@web3-onboard/react'
import { ethers } from 'ethers'





const Header = () => {
    const [classess, setClassess] = useState(["", ""]);
    const { ethereum } = window;
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
    let ethersProvider

    if (wallet) {
        ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
    }



    const addTokenToWallet = async () => {
        try {

            const wasAdded = await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: "0x8cc67227886762E65Edf9d69FA6070C3Eb45Dee2",
                        symbol: "ECASHX",
                        decimals: 18,
                        // image: ""
                    },
                },
            });

            if (wasAdded) {
                console.log('Thanks for your interest!');
            } else {
                console.log('Quani Coin has not been added');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSideBar = () => {
        let body = document.body;
        if (!body.classList.contains("on-side")) {
            setClassess(["active", "show"]);
            body.classList.add("on-side");
        } else {
            setClassess(["", ""]);
            body.classList.remove("on-side");
        }
    };

    return (
        <>
            <header class="site-header header--transparent ico-header">
                <div class="header__main-wrap stricky">
                    <div class="container mxw_1640">
                        <div class="header__main ul_li_between">
                            <div class="header__left ul_li">
                                <div class="header__logo">
                                    <a href="/"><img src="assets/img/logo/logo.svg" alt="" /></a>
                                </div>
                                <div class="d-xl-none">
                                    <a class={`header__bar hamburger_menu ${classess?.[0]}`} href="javascript:void(0);" onClick={handleSideBar}>
                                        <div class="header__bar-icon">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="main-menu__wrap ul_li navbar navbar-expand-xl">
                                <nav class="main-menu collapse navbar-collapse">
                                    <ul>
                                        <li> <Link to="/"> Home </Link> </li>
                                        <li><a class="scrollspy-btn" href="#about">About Us</a></li>
                                        <li><a class="scrollspy-btn" href="#features">Features</a></li>
                                        <li><a class="scrollspy-btn" href="#token">Token</a></li>
                                        <li><a class="scrollspy-btn" href="#roadmap">Roadmap</a></li>
                                        <li><a href="ECASH-X FINAL WHITEPAPER.pdf" target='_blank'>Whitepaper</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="header__action ul_li">
                                <div className="btns">
                                    <Link to="/buyToken" className="thm-btn" onClick={addTokenToWallet}>Add Token To Wallet</Link>
                                    <button className="thm-btn thm-btn--light" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                                        {connecting ? 'Connecting' : wallet ? `Disconnect ` : 'Connect Wallet'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <aside class={`slide-bar ${classess?.[1]}`}>
                <div class="close-mobile-menu">
                    <a class="tx-close" href="javascript:void(0);" onClick={handleSideBar}></a>
                </div>
                <nav class="side-mobile-menu">
                    <a class="header__logo mb-30" href="#!">
                        <img src="assets/img/logo/logo.svg" alt="" />
                    </a>
                    <ul id="mobile-menu-active">
                        <li> <a href="/"> Home </a> </li>
                        <li><a class="scrollspy-btn" href="#about" onClick={handleSideBar}>About Us</a></li>
                        <li><a class="scrollspy-btn" href="#features" onClick={handleSideBar}>Features</a></li>
                        <li><a class="scrollspy-btn" href="#token" onClick={handleSideBar}>Token</a></li>
                        <li><a class="scrollspy-btn" href="#roadmap" onClick={handleSideBar}>Roadmap</a></li>
                        <li><a href="ECASH-X FINAL WHITEPAPER.pdf" target='_blank' onClick={handleSideBar}>Whitepaper</a></li>
                    </ul>
                </nav>
            </aside>
            <div class={`body-overlay ${classess?.[0]}`}></div>
        </>
    );
};

export default Header;
